import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiService from '../services/api.service';
import '../styles/ARReview.css';

export default function ARReviewForm() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    validateTokenAndFetchData();
  }, [token]);

  const validateTokenAndFetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await apiService.getARReview(token);
      if (!response.data || !response.data.arRequest) {
        throw new Error('Invalid AR review data received');
      }
      setRequestData(response.data.arRequest);
      setProjects(response.data.projects || []);
      const initialFormData = {};
      (response.data.projects || []).forEach(project => {
        initialFormData[project.id] = {
          cod: project.cod || false,
          amountDue: project.amountDue ? project.amountDue.toString() : '',
          notes: ''
        };
      });
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error loading AR review:', error);
      setError(error.response?.data?.error || 'Failed to load AR review data');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (projectId, field, value) => {
  setFormData(prev => ({
    ...prev,
    [projectId]: {
      ...prev[projectId],
      [field]: value // Store value as-is without any modifications
    }
  }));
};

  const notifyParentWindow = () => {
    try {
      if (window.opener && !window.opener.closed) {
        console.log('Notifying parent window via window.opener');
        window.opener.dispatchEvent(new Event('ar-review-completed'));
      }

      const timestamp = new Date().getTime();
      console.log('Notifying via localStorage update');
      localStorage.setItem('ar-review-completed', timestamp.toString());
      
      try {
        console.log('Attempting to notify via BroadcastChannel');
        const channel = new BroadcastChannel('ar-review-channel');
        channel.postMessage({ type: 'ar-review-completed', timestamp });
        channel.close();
      } catch (error) {
        console.warn('BroadcastChannel not supported:', error);
      }
    } catch (error) {
      console.error('Error notifying parent window:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      setLoading(true);
      const updates = Object.entries(formData)
        .filter(([_, data]) => data.cod || data.notes.trim())
        .map(([projectId, data]) => ({
          projectId: parseInt(projectId),
          cod: Boolean(data.cod),
          amountDue: data.amountDue.trim(),
          notes: data.notes.trim() ? data.notes.trim() : ''
        }));

      console.log('Submitting AR review updates:', updates);
      await apiService.submitARReview(token, { updates });
      
      notifyParentWindow();
      
      navigate(`/ar-review/${token}/success`);
    } catch (error) {
      console.error('Error submitting review:', error);
      setError(error.response?.data?.error || 'Failed to submit review. Please try again.');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="ar-loading-container">
        <div className="ar-loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="ar-error-container">
        <div className="ar-error-message">{error}</div>
      </div>
    );
  }

  if (!requestData) {
    return (
      <div className="ar-error-container">
        <div className="ar-error-message">No AR review data available</div>
      </div>
    );
  }

  return (
    <div className="ar-review-container">
      <div className="ar-review-content">
        <div className="ar-review-header">
          <h1>AR Schedule Review</h1>
          <div className="ar-review-date">
            <p>Review Period: {new Date(requestData.startDate).toLocaleDateString()} - {new Date(requestData.endDate).toLocaleDateString()}</p>
            <p>Branch: {requestData.branch.name}</p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="ar-projects-list">
            {projects.map(project => (
              <div key={project.id} className="ar-project-card">
                <div className="ar-project-header">
                  <h3>{project.name}</h3>
                  <p><strong>Customer ACCT:</strong> {project.customerId || 'N/A'}</p>
                  <p><strong>Document #:</strong> {project.documentNumber || 'N/A'}</p>
                  <p><strong>Date:</strong> {new Date(project.dateTime).toLocaleDateString()}</p>
                </div>
                <div className="ar-project-grid">
                  <div className="ar-project-details">
                    <p><strong>Location:</strong> {project.location}</p>
                  </div>
                  <div className="ar-project-scope">
                    <p><strong>Scope:</strong> {project.scope}</p>
                    <div className="ar-project-payment">
                      <label className="ar-cod-label">
                        <input
                          type="checkbox"
                          checked={formData[project.id]?.cod || false}
                          onChange={(e) => handleInputChange(project.id, 'cod', e.target.checked)}
                          className="ar-cod-checkbox"
                        />
                        C.O.D.
                      </label>
                      {formData[project.id]?.cod && (
                        <div className="ar-amount-field">
                          <label>Amount Due:</label>
                          <input
                            type="text"
                            value={formData[project.id]?.amountDue || ''}
                            onChange={(e) => handleInputChange(project.id, 'amountDue', e.target.value)}
                            placeholder="Enter amount or note"
                            className="ar-amount-input"
                            required
                          />
                          <span className="ar-amount-help">
                            (Enter amount or text like "PAID")
                          </span>
                        </div>
                      )}
                      <div className="ar-notes-field">
                        <label>Notes:</label>
                        <textarea
                          value={formData[project.id]?.notes || ''}
                          onChange={(e) => handleInputChange(project.id, 'notes', e.target.value)}
                          placeholder="Enter any notes about this project"
                          className="ar-notes-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="ar-form-actions">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="ar-button ar-button-secondary"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="ar-button ar-button-primary"
            >
              {loading ? 'Submitting...' : 'Submit Review'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}