import React, { useState } from 'react';
import '../styles/ProjectForm.css';

function ProjectForm({ 
  onSave, 
  onClose, 
  selectedDate, 
  selectedBranch, 
  selectedSubBranch, 
  technicians, 
  contractors,
  isSaving
}) {
  const [newProject, setNewProject] = useState({
    customerId: '',
    name: '',
    location: '',
    customerEmail: '',
    dateTime: setDefaultDateTime(),
    scope: '',
    technicianIds: [],
    contractorIds: [],
    partsRequired: false,
    notes: '',
    branchId: selectedSubBranch?.id || selectedBranch?.id || null,
    documentNumber: ''
  });

  const [duration, setDuration] = useState(1);
  const [saveError, setSaveError] = useState(null);
  const [savingMessage, setSavingMessage] = useState('');

  function setDefaultDateTime() {
    const now = selectedDate || new Date();
    now.setHours(8, 0, 0, 0);
    return formatDateTimeForInput(now);
  }

  function formatDateTimeForInput(date) {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - (offset*60*1000));
    return adjustedDate.toISOString().slice(0, 16);
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewProject(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleMultiSelect = (e, field) => {
    const { value, checked } = e.target;
    const id = Number(value);
    setNewProject(prev => ({
      ...prev,
      [field]: checked
        ? [...prev[field], id]
        : prev[field].filter(existingId => existingId !== id)
    }));
  };

  const createProjectsForDuration = (projectData, duration) => {
    const projects = [];
    let currentDate = new Date(projectData.dateTime);
    let daysCreated = 0;
    
    while (daysCreated < duration) {
      // Skip weekends
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        projects.push({
          ...projectData,
          dateTime: new Date(currentDate).toISOString(),
          name: duration > 1 ? `${projectData.name} (Day ${daysCreated + 1}/${duration})` : projectData.name
        });
        daysCreated++;
      }
      // Move to next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return projects;
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (isSaving) {
      console.log('Save already in progress, ignoring click');
      return;
    }

    setSaveError(null);
    setSavingMessage('');

    try {
      const projectToSave = {
        ...newProject,
        technicianIds: newProject.technicianIds.map(Number),
        contractorIds: newProject.contractorIds.map(Number),
        dateTime: new Date(newProject.dateTime).toISOString(),
        branchId: Number(newProject.branchId)
      };

      console.log('Submitting project data:', projectToSave);

      if (duration === 1) {
        const result = await onSave(projectToSave);
        console.log('Save result:', result);
        
        if (!result?.success) {
          throw new Error(result?.error || 'Failed to save project');
        }

        console.log('Save successful:', result);
        onClose();
      } else {
        const projects = createProjectsForDuration(projectToSave, duration);
        for (const [index, project] of projects.entries()) {
          setSavingMessage(`Saving project ${index + 1} of ${projects.length}`);
          const result = await onSave(project);
          console.log(`Save result for project ${index + 1}:`, result);
          
          if (!result?.success) {
            throw new Error(result?.error || `Failed to save project ${index + 1} of ${projects.length}`);
          }
        }
        
        console.log('All projects saved successfully');
        onClose();
      }
    } catch (error) {
      console.error('Error saving project:', error);
      setSaveError(error.message);
    }
  };

  const availableTechnicians = technicians.filter(tech => 
    tech.branches && tech.branches.some(branch => branch.branchId === Number(newProject.branchId))
  );

  const availableContractors = contractors.filter(contractor => 
    contractor.branchId === Number(newProject.branchId)
  );

  return (
    <div className="modal-backdrop" onClick={(e) => {
      if (e.target.className === 'modal-backdrop' && !isSaving) {
        onClose();
      }
    }}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>New Project</h2>
        {saveError && (
          <div className="error-banner">
            {saveError}
          </div>
        )}
        {savingMessage && (
          <div className="saving-message">
            {savingMessage}
          </div>
        )}
        <form 
          onSubmit={handleSaveClick}
          className="project-form"
          onClick={(e) => e.stopPropagation()}
          style={{ position: 'relative' }}
        >
          <div className="form-group">
            <label htmlFor="customerId">Customer ACCT</label>
            <input
              type="text"
              id="customerId"
              name="customerId"
              value={newProject.customerId || ''}
              onChange={handleChange}
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="documentNumber">Document #</label>
            <input
              type="text"
              id="documentNumber"
              name="documentNumber"
              value={newProject.documentNumber || ''}
              onChange={handleChange}
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="customerEmail">Customer Email</label>
            <input
              type="email"
              id="customerEmail"
              name="customerEmail"
              value={newProject.customerEmail || ''}
              onChange={handleChange}
              className="flex-1"
            />
          </div>

          <div className="form-group">
            <label htmlFor="name">Customer Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newProject.name || ''}
              onChange={handleChange}
              required
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="location">Project Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={newProject.location || ''}
              onChange={handleChange}
              required
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="dateTime">Project Start Date/Time</label>
            <input
              type="datetime-local"
              id="dateTime"
              name="dateTime"
              value={newProject.dateTime || ''}
              onChange={handleChange}
              required
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="duration">Project Duration (Business Days)</label>
            <select
              id="duration"
              value={duration}
              onChange={(e) => setDuration(parseInt(e.target.value))}
              className="w-full"
            >
              {Array.from({length: 14}, (_, i) => i + 1).map(days => (
                <option key={days} value={days}>
                  {days} {days === 1 ? 'Day' : 'Days'}
                </option>
              ))}
            </select>
            {duration > 1 && (
              <p className="text-sm text-gray-500 mt-1">
                This will create {duration} separate projects, one for each business day (Mon-Fri)
              </p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="scope">Project Scope</label>
            <textarea
              id="scope"
              name="scope"
              value={newProject.scope || ''}
              onChange={handleChange}
              required
              className="w-full h-32"
            />
          </div>

          <div className="form-group">
            <h3>Assign Technicians</h3>
            {availableTechnicians.length > 0 ? (
              availableTechnicians.map(tech => (
                <div key={tech.id} className="flex items-center space-x-2">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={tech.id}
                      checked={newProject.technicianIds.map(Number).includes(Number(tech.id))}
                      onChange={(e) => handleMultiSelect(e, 'technicianIds')}
                    />
                    <span>{tech.name}</span>
                  </label>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No technicians available for this branch</p>
            )}
          </div>

          <div className="form-group">
            <h3>Assign Contractors</h3>
            {availableContractors.length > 0 ? (
              availableContractors.map(contractor => (
                <div key={contractor.id} className="flex items-center space-x-2">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={contractor.id}
                      checked={newProject.contractorIds.map(Number).includes(Number(contractor.id))}
                      onChange={(e) => handleMultiSelect(e, 'contractorIds')}
                    />
                    <span>{contractor.name}</span>
                  </label>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No contractors available for this branch</p>
            )}
          </div>

          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="partsRequired"
                checked={newProject.partsRequired || false}
                onChange={handleChange}
              />
              <strong>Parts Required</strong>
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="notes">Notes</label>
            <textarea
              id="notes"
              name="notes"
              value={newProject.notes || ''}
              onChange={handleChange}
              className="w-full h-24"
            />
          </div>

          <div className="form-actions">
            <button 
              type="submit"
              className="btn-save"
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Create Project'}
            </button>
            <button 
              type="button"
              onClick={onClose}
              className="btn-cancel"
              disabled={isSaving}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProjectForm;