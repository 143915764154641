import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import apiService from './services/api.service';
import { useAuth } from './authContext';

const BranchContext = createContext();

export const useBranch = () => useContext(BranchContext);

export const BranchProvider = ({ children }) => {
  const { user } = useAuth();
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedSubBranch, setSelectedSubBranch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize branches and restore selections
  useEffect(() => {
    const initializeBranches = async () => {
      if (!user) {
        setBranches([]);
        setSelectedBranch(null);
        setSelectedSubBranch(null);
        setIsInitialized(false);
        return;
      }

      if (window.location.pathname.includes('/login')) {
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const response = await apiService.getBranch();
        setBranches(response.data);
        
        // Only restore selections after we have the branch data
        const savedBranchId = localStorage.getItem('selectedBranchId');
        const savedSubBranchId = localStorage.getItem('selectedSubBranchId');
        
        if (savedBranchId) {
          const branch = response.data.find(b => b.id === parseInt(savedBranchId));
          if (branch) {
            setSelectedBranch(branch);
            if (savedSubBranchId) {
              const subBranch = branch.subBranches?.find(sb => sb.id === parseInt(savedSubBranchId));
              if (subBranch) {
                setSelectedSubBranch(subBranch);
              }
            }
          }
        }
        setIsInitialized(true);
      } catch (error) {
        if (!window.location.pathname.includes('/login')) {
          console.error('Error fetching branches:', error);
          setError('Failed to fetch branches. Please try again.');
        }
        setIsInitialized(false);
      } finally {
        setIsLoading(false);
      }
    };

    initializeBranches();
  }, [user]);

  // Save branch selections when they change
  useEffect(() => {
    if (!isInitialized) return;
    
    if (selectedBranch) {
      localStorage.setItem('selectedBranchId', selectedBranch.id.toString());
    } else {
      localStorage.removeItem('selectedBranchId');
    }
  }, [selectedBranch, isInitialized]);

  useEffect(() => {
    if (!isInitialized) return;
    
    if (selectedSubBranch) {
      localStorage.setItem('selectedSubBranchId', selectedSubBranch.id.toString());
    } else {
      localStorage.removeItem('selectedSubBranchId');
    }
  }, [selectedSubBranch, isInitialized]);

  const refreshBranches = useCallback(async () => {
    if (!user || !isInitialized) return;
    
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiService.getBranch();
      setBranches(response.data);
      
      // Validate current selections against new data
      if (selectedBranch) {
        const updatedBranch = response.data.find(b => b.id === selectedBranch.id);
        if (!updatedBranch) {
          setSelectedBranch(null);
          setSelectedSubBranch(null);
          localStorage.removeItem('selectedBranchId');
          localStorage.removeItem('selectedSubBranchId');
        }
      }
    } catch (error) {
      if (!window.location.pathname.includes('/login')) {
        console.error('Error fetching branches:', error);
        setError('Failed to fetch branches. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [user, selectedBranch, isInitialized]);

  const addBranch = async (branchData) => {
    if (!user) return null;
    
    try {
      const response = await apiService.createBranch(branchData);
      await refreshBranches();
      return response.data;
    } catch (error) {
      if (!window.location.pathname.includes('/login')) {
        console.error('Error adding branch:', error);
      }
      throw error;
    }
  };

  const addSubBranch = async (parentBranchId, subBranchData) => {
    if (!user) return null;
    
    try {
      const response = await apiService.createBranch({
        ...subBranchData,
        parentBranchId
      });
      await refreshBranches();
      return response.data;
    } catch (error) {
      if (!window.location.pathname.includes('/login')) {
        console.error('Error adding sub-branch:', error);
      }
      throw error;
    }
  };

  const deleteBranch = async (branchId) => {
    if (!user) return;
    
    try {
      await apiService.deleteBranch(branchId);
      if (selectedBranch?.id === branchId) {
        setSelectedBranch(null);
        setSelectedSubBranch(null);
      } else if (selectedSubBranch?.id === branchId) {
        setSelectedSubBranch(null);
      }
      await refreshBranches();
    } catch (error) {
      if (!window.location.pathname.includes('/login')) {
        console.error('Error deleting branch:', error);
      }
      throw error;
    }
  };

  return (
    <BranchContext.Provider
      value={{
        branches,
        selectedBranch,
        selectedSubBranch,
        isLoading,
        error,
        setSelectedBranch,
        setSelectedSubBranch,
        refreshBranches,
        addBranch,
        addSubBranch,
        deleteBranch
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};

export default BranchContext;