import React, { useState, useEffect } from 'react';
import EmailRecipientInput from './EmailRecipientInput';
import '../styles/EmailSettings.css';

function EmailSettings({ onClose, initialSettings, onSave, branchId }) {
  const [settings, setSettings] = useState({
    sendDailyReport: false,
    sendWeeklyReport: false,
    notifyNewProject: false,
    notifyProjectUpdate: false,
    technicianReminderDays: 3,
    weeklyReportSchedule: { day: 'Friday', time: '15:30' },
    reportRecipients: [],
    arEnabled: false,
    arRecipients: [],
    branchId: branchId,
  });

  useEffect(() => {
    if (initialSettings) {
      // Convert comma-separated strings to arrays when loading initial settings
      const reportRecipients = typeof initialSettings.reportRecipients === 'string'
        ? initialSettings.reportRecipients.split(',').map(email => email.trim()).filter(Boolean)
        : [];

      const arRecipients = typeof initialSettings.arRecipients === 'string'
        ? initialSettings.arRecipients.split(',').map(email => email.trim()).filter(Boolean)
        : [];

      setSettings(prev => ({
        ...prev,
        ...initialSettings,
        sendDailyReport: !!initialSettings.sendDailyReport,
        sendWeeklyReport: !!initialSettings.sendWeeklyReport,
        notifyNewProject: !!initialSettings.notifyNewProject,
        notifyProjectUpdate: !!initialSettings.notifyProjectUpdate,
        arEnabled: !!initialSettings.arEnabled,
        reportRecipients,
        arRecipients
      }));
    }
  }, [initialSettings]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Convert arrays back to comma-separated strings for API
    const formattedSettings = {
      ...settings,
      reportRecipients: settings.reportRecipients.join(','),
      arRecipients: settings.arRecipients.join(',')
    };
    await onSave(formattedSettings);
  };

  return (
    <div className="email-settings">
      <h2>Email Settings</h2>
      <form onSubmit={handleSubmit} className="email-settings-form">
        <div className="form-group">
          <h3>Notifications</h3>
          <label>
            <input
              type="checkbox"
              name="sendDailyReport"
              checked={settings.sendDailyReport}
              onChange={handleChange}
            />
            Send Daily Report (at 8:00 AM)
          </label>
          <label>
            <input
              type="checkbox"
              name="sendWeeklyReport"
              checked={settings.sendWeeklyReport}
              onChange={handleChange}
            />
            Send Weekly Report (Friday at 3:30 PM)
          </label>
          <label>
            <input
              type="checkbox"
              name="notifyNewProject"
              checked={settings.notifyNewProject}
              onChange={handleChange}
            />
            Notify on New Project
          </label>
          <label>
            <input
              type="checkbox"
              name="notifyProjectUpdate"
              checked={settings.notifyProjectUpdate}
              onChange={handleChange}
            />
            Notify on Project Update
          </label>
        </div>

        <div className="form-group">
          <h3>Report Recipients</h3>
          <EmailRecipientInput
            recipients={settings.reportRecipients}
            onChange={(recipients) => setSettings(prev => ({
              ...prev,
              reportRecipients: recipients
            }))}
            placeholder="Enter report recipient emails..."
          />
        </div>

        <div className="form-group">
          <h3>AR Integration</h3>
          <label>
            <input
              type="checkbox"
              name="arEnabled"
              checked={settings.arEnabled}
              onChange={handleChange}
            />
            Enable AR Integration
          </label>
          <div className="ar-recipients">
            <EmailRecipientInput
              recipients={settings.arRecipients}
              onChange={(recipients) => setSettings(prev => ({
                ...prev,
                arRecipients: recipients
              }))}
              placeholder="Enter AR recipient emails..."
              disabled={!settings.arEnabled}
            />
          </div>
        </div>

        <div className="form-group">
          <h3>Technician Reminders</h3>
          <div className="reminder-setting">
            <label htmlFor="technicianReminderDays">Send reminder</label>
            <select
              id="technicianReminderDays"
              name="technicianReminderDays"
              value={settings.technicianReminderDays}
              onChange={handleChange}
            >
              {[1, 2, 3, 4, 5, 6, 7].map(day => (
                <option key={day} value={day}>{day} day{day > 1 ? 's' : ''}</option>
              ))}
            </select>
            <span>before project</span>
          </div>
        </div>

        <div className="form-actions">
          <button type="submit" className="btn-save">Save Settings</button>
          <button type="button" onClick={onClose} className="btn-cancel">Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default EmailSettings;