import React, { useState, useRef } from 'react';
import { X } from 'lucide-react';

const EmailRecipientInput = ({ 
  recipients = [], 
  onChange,
  placeholder = "Enter email addresses...",
  className = "",
  disabled = false
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const inputRef = useRef(null);
  
  const emailList = Array.isArray(recipients) ? recipients : [];
  
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    
    if (value.endsWith(',')) {
      addEmail(value.slice(0, -1).trim());
    } else {
      setIsValid(true);
    }
  };

  const addEmail = (email) => {
    if (email && validateEmail(email)) {
      if (!emailList.includes(email)) {
        onChange([...emailList, email]);
      }
      setInputValue('');
      setIsValid(true);
    } else if (email) {
      setIsValid(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addEmail(inputValue.trim());
    } else if (e.key === 'Backspace' && !inputValue && emailList.length > 0) {
      e.preventDefault();
      onChange(emailList.slice(0, -1));
    }
  };

  const removeRecipient = (indexToRemove) => {
    onChange(emailList.filter((_, index) => index !== indexToRemove));
    inputRef.current?.focus();
  };

  const containerClasses = [
    'email-input-container',
    !isValid && 'invalid',
    disabled && 'disabled',
    className
  ].filter(Boolean).join(' ');

  return (
    <div className={containerClasses}>
      {emailList.map((email, index) => (
        <div
          key={index}
          className={`email-chip ${disabled ? 'disabled' : ''}`}
        >
          <span className="email-chip-text">{email}</span>
          <button
            type="button"
            onClick={() => removeRecipient(index)}
            className="remove-button"
            disabled={disabled}
          >
            <X size={12} />
          </button>
        </div>
      ))}
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={emailList.length === 0 ? placeholder : ''}
        className="email-input"
        disabled={disabled}
      />
    </div>
  );
};

export default EmailRecipientInput;