import React, { useState, useEffect, useRef } from 'react';
import { useEmailTracking } from '../hooks/useEmailTracking';
import DeliveryStatus from './DeliveryStatus';
import EmailTrackingInfo from './EmailTrackingInfo';
import '../styles/ProjectForm.css';

function EditProject({ 
  project, 
  onSave, 
  onClose, 
  onDelete, 
  selectedBranch, 
  selectedSubBranch, 
  technicians, 
  contractors,
  isSaving
}) {
  const [editedProject, setEditedProject] = useState({
    customerId: '',
    name: '',
    location: '',
    customerEmail: '',
    dateTime: '',
    scope: '',
    technicianIds: [],
    contractorIds: [],
    partsRequired: false,
    cod: false,
    amountDue: null,
    notes: '',
    branchId: null,
    documentNumber: ''
  });

  const [saveError, setSaveError] = useState(null);
  const originalProjectRef = useRef(null);

  const { emailStatus, refreshStatus } = useEmailTracking(project?.id);

  // Store the original project for comparison
  useEffect(() => {
    if (project) {
      originalProjectRef.current = {
        ...project,
        technicianIds: project.technicians?.map(t => Number(t.id)) || [],
        contractorIds: project.contractors?.map(c => Number(c.id)) || []
      };
    }
  }, [project]);

  useEffect(() => {
    if (project) {
      // Ensure all IDs are numbers
      const techIds = project.technicians?.map(t => Number(t.id)) || [];
      const contIds = project.contractors?.map(c => Number(c.id)) || [];
      
      setEditedProject({
        ...project,
        dateTime: formatDateTimeForInput(new Date(project.dateTime)),
        technicianIds: techIds,
        contractorIds: contIds,
        customerEmail: project.customerEmail || '',
        customerId: project.customerId || '',
        cod: project.cod || false,
        amountDue: project.amountDue || null,
        branchId: Number(project.branchId) || Number(selectedSubBranch?.id) || Number(selectedBranch?.id),
        documentNumber: project.documentNumber || ''
      });
    }
  }, [project, selectedBranch, selectedSubBranch]);

  function formatDateTimeForInput(date) {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - (offset*60*1000));
    return adjustedDate.toISOString().slice(0, 16);
  }

  function formatCurrency(value) {
    if (!value) return '';
    
    if (typeof value === 'number') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    }
    
    if (typeof value === 'string') {
      if (/^[$\d].*\d/.test(value.trim())) {
        const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
        if (!isNaN(numericValue)) {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(numericValue);
        }
      }
      return value;
    }
    
    return value.toString();
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'amountDue') {
      setEditedProject(prev => ({
        ...prev,
        [name]: value || null
      }));
    } else {
      setEditedProject(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleMultiSelect = (e, field) => {
    const { value, checked } = e.target;
    const id = Number(value);
    setEditedProject(prev => ({
      ...prev,
      [field]: checked
        ? [...prev[field], id]
        : prev[field].filter(existingId => existingId !== id)
    }));
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (isSaving) {
      console.log('Save already in progress, ignoring click');
      return;
    }

    setSaveError(null);

    try {
      const projectToSave = {
        ...editedProject,
        technicianIds: editedProject.technicianIds.map(Number),
        contractorIds: editedProject.contractorIds.map(Number),
        dateTime: new Date(editedProject.dateTime).toISOString(),
        branchId: Number(editedProject.branchId),
        cod: Boolean(editedProject.cod),
        amountDue: editedProject.amountDue
      };

      console.log('Submitting edited project data:', projectToSave);

      const result = await onSave(projectToSave);
      console.log('Save result:', result);
      
      if (!result?.success) {
        throw new Error(result?.error || 'Failed to save project');
      }

      console.log('Save successful:', result);
      onClose();
    } catch (error) {
      console.error('Error saving project:', error);
      setSaveError(error.message);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this project?')) {
      onDelete(project.id);
    }
  };

  const availableTechnicians = technicians.filter(tech => 
    tech.branches && tech.branches.some(branch => branch.branchId === Number(editedProject.branchId))
  );

  const availableContractors = contractors.filter(contractor => 
    contractor.branchId === Number(editedProject.branchId)
  );

  return (
    <div className="modal-backdrop" onClick={(e) => {
      if (e.target.className === 'modal-backdrop' && !isSaving) {
        onClose();
      }
    }}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>Edit Project</h2>
        {saveError && (
          <div className="error-banner">
            {saveError}
          </div>
        )}
        <form 
          onSubmit={handleSaveClick}
          className="project-form"
          onClick={(e) => e.stopPropagation()}
          style={{ position: 'relative' }}
        >
          <div className="form-group">
            <label htmlFor="customerId">Customer ACCT</label>
            <input
              type="text"
              id="customerId"
              name="customerId"
              value={editedProject.customerId || ''}
              onChange={handleChange}
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="documentNumber">Document #</label>
            <input
              type="text"
              id="documentNumber"
              name="documentNumber"
              value={editedProject.documentNumber || ''}
              onChange={handleChange}
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="customerEmail">Customer Email</label>
            <div className="flex items-center">
              <input
                type="email"
                id="customerEmail"
                name="customerEmail"
                value={editedProject.customerEmail || ''}
                onChange={handleChange}
                className="flex-1"
              />
              {emailStatus?.customer && (
                <DeliveryStatus 
                  status={emailStatus.customer.status}
                  sentAt={emailStatus.customer.sentAt}
                  deliveredAt={emailStatus.customer.deliveredAt}
                />
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="name">Customer Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={editedProject.name || ''}
              onChange={handleChange}
              required
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="location">Project Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={editedProject.location || ''}
              onChange={handleChange}
              required
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="dateTime">Project Start Date/Time</label>
            <input
              type="datetime-local"
              id="dateTime"
              name="dateTime"
              value={editedProject.dateTime || ''}
              onChange={handleChange}
              required
              className="w-full"
            />
          </div>

          <div className="form-group">
            <label htmlFor="scope">Project Scope</label>
            <textarea
              id="scope"
              name="scope"
              value={editedProject.scope || ''}
              onChange={handleChange}
              required
              className="w-full h-32"
            />
          </div>

          <div className="form-group">
            <h3>Assign Technicians</h3>
            {availableTechnicians.length > 0 ? (
              availableTechnicians.map(tech => (
                <div key={tech.id} className="flex items-center justify-between">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={tech.id}
                      checked={editedProject.technicianIds.map(Number).includes(Number(tech.id))}
                      onChange={(e) => handleMultiSelect(e, 'technicianIds')}
                    />
                    <span>{tech.name}</span>
                  </label>
                  {emailStatus?.technicians[tech.id] && (
                    <DeliveryStatus 
                      status={emailStatus.technicians[tech.id].status}
                      sentAt={emailStatus.technicians[tech.id].sentAt}
                      deliveredAt={emailStatus.technicians[tech.id].deliveredAt}
                    />
                  )}
                </div>
              ))
            ) : (
              <p className="text-gray-500">No technicians available for this branch</p>
            )}
          </div>

          <div className="form-group">
            <h3>Assign Contractors</h3>
            {availableContractors.length > 0 ? (
              availableContractors.map(contractor => (
                <div key={contractor.id} className="flex items-center justify-between">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={contractor.id}
                      checked={editedProject.contractorIds.map(Number).includes(Number(contractor.id))}
                      onChange={(e) => handleMultiSelect(e, 'contractorIds')}
                    />
                    <span>{contractor.name}</span>
                  </label>
                  {emailStatus?.contractors[contractor.id] && (
                    <DeliveryStatus 
                      status={emailStatus.contractors[contractor.id].status}
                      sentAt={emailStatus.contractors[contractor.id].sentAt}
                      deliveredAt={emailStatus.contractors[contractor.id].deliveredAt}
                    />
                  )}
                </div>
              ))
            ) : (
              <p className="text-gray-500">No contractors available for this branch</p>
            )}
          </div>

          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="partsRequired"
                checked={editedProject.partsRequired || false}
                onChange={handleChange}
              />
              <strong>Parts Required</strong>
            </label>
          </div>

          <div className="form-group">
            <div className="payment-info">
              <p><strong>C.O.D.:</strong> {editedProject.cod ? 'Yes' : 'No'}</p>
              {editedProject.cod && (
                <p><strong>Amount Due:</strong> {formatCurrency(editedProject.amountDue)}</p>
              )}
              <p className="note">* COD and Amount Due are managed through AR Review</p>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="notes">Notes</label>
            <textarea
              id="notes"
              name="notes"
              value={editedProject.notes || ''}
              onChange={handleChange}
              className="w-full h-24"
            />
          </div>

          <EmailTrackingInfo
            emailStatus={emailStatus}
            onRefresh={refreshStatus}
            technicians={editedProject.technicianIds.map(id => 
              technicians.find(t => Number(t.id) === Number(id))
            ).filter(Boolean)}
            contractors={editedProject.contractorIds.map(id => 
              contractors.find(c => Number(c.id) === Number(id))
            ).filter(Boolean)}
            customerEmail={editedProject.customerEmail}
          />

          <div className="form-actions">
            <button 
              type="submit"
              className="btn-save"
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save Project'}
            </button>
            <button 
              type="button"
              onClick={onClose}
              className="btn-cancel"
              disabled={isSaving}
            >
              Cancel
            </button>
            <button 
              type="button"
              onClick={handleDelete}
              className="btn-delete"
              disabled={isSaving}
            >
              Delete Project
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditProject;