import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/api.service';
import EmailSettings from './EmailSettings';
import { useBranch } from '../BranchContext';
import '../styles/Settings.css';

function Settings() {
  const navigate = useNavigate();
  const { selectedBranch, setSelectedBranch, selectedSubBranch, setSelectedSubBranch, branches, refreshBranches } = useBranch();
  const [data, setData] = useState({ technicians: [], contractors: [], emailSettings: null });
  const [activeTab, setActiveTab] = useState('technicians');
  const [newItem, setNewItem] = useState({ name: '', email: '', phone: '', contact: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newBranch, setNewBranch] = useState({ main: '', sub: '' });
  const [confirmDelete, setConfirmDelete] = useState(null);

  useEffect(() => {
    if (selectedBranch) fetchData();
    else setLoading(false);
  }, [selectedBranch, selectedSubBranch]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const branchId = selectedSubBranch ? selectedSubBranch.id : selectedBranch.id;
      const [technicians, contractors, emailSettings] = await Promise.all([
        apiService.getTechnicians(branchId),
        apiService.getContractor(branchId),
        apiService.getEmailSettings(branchId)
      ]);
      setData({ technicians: technicians.data, contractors: contractors.data, emailSettings: emailSettings.data || null });
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load some settings data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => setNewItem({ ...newItem, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      let response;
      const branchId = selectedSubBranch ? selectedSubBranch.id : selectedBranch.id;
      if (activeTab === 'technicians') {
        response = await apiService.createTechnician({ ...newItem, branchIds: [branchId] });
        setData(prev => {
          const updatedTechnicians = prev.technicians.filter(t => t.id !== response.data.id);
          return { ...prev, technicians: [...updatedTechnicians, response.data] };
        });
      } else if (activeTab === 'contractor') {
        response = await apiService.createContractor({ ...newItem, branchId });
        setData(prev => ({ ...prev, contractors: [...prev.contractors, response.data] }));
      }
      setNewItem({ name: '', email: '', phone: '', contact: '' });
    } catch (error) {
      console.error(`Error creating/updating ${activeTab}:`, error);
      setError(`Failed to create/update ${activeTab}. Please try again.`);
    }
  };

  const handleDelete = async (id) => {
    setError(null);
    try {
      const branchId = selectedSubBranch ? selectedSubBranch.id : selectedBranch.id;
      if (activeTab === 'technicians') {
        await apiService.deleteTechnician(id, branchId);
        setData(prev => ({ ...prev, technicians: prev.technicians.filter(tech => tech.id !== id) }));
      } else if (activeTab === 'contractor') {
        await apiService.deleteContractor(id);
        setData(prev => ({ ...prev, contractors: prev.contractors.filter(c => c.id !== id) }));
      }
    } catch (error) {
      console.error(`Error deleting ${activeTab}:`, error);
      setError(`Failed to delete ${activeTab}. Please try again.`);
    }
  };

  const handleSaveEmailSettings = async (settings) => {
    try {
      setLoading(true);
      await apiService.updateEmailSettings(settings);
      await fetchData();
      setError(null);
    } catch (err) {
      console.error('Error saving email settings:', err);
      setError('Failed to save email settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddBranch = async (e, type) => {
    e.preventDefault();
    setError(null);
    try {
      const branchData = type === 'main'
        ? { name: newBranch.main }
        : { name: newBranch.sub, parentBranchId: selectedBranch.id };
      
      const response = await apiService.createBranch(branchData);
      await refreshBranches();
      
      // If adding a sub-branch, update the selectedBranch with the new sub-branch
      if (type === 'sub' && selectedBranch) {
        // Fetch the updated branch with its sub-branches
        const updatedBranchResponse = await apiService.getBranch();
        const updatedBranches = updatedBranchResponse.data;
        const updatedBranch = updatedBranches.find(b => b.id === selectedBranch.id);
        
        if (updatedBranch) {
          setSelectedBranch(updatedBranch);
        }
      }
      
      setNewBranch({ ...newBranch, [type]: '' });
    } catch (error) {
      console.error(`Error creating ${type} branch:`, error);
      setError(`Failed to create ${type} branch. Please try again.`);
    }
  };

  const handleDeleteBranch = async (branchId) => {
    setError(null);
    try {
      await apiService.deleteBranch(branchId);
      if (selectedSubBranch && selectedSubBranch.id === branchId) {
        setSelectedSubBranch(null);
      } else if (selectedBranch && selectedBranch.id === branchId) {
        setSelectedBranch(null);
        setSelectedSubBranch(null);
      } else if (selectedBranch) {
        setSelectedBranch(prev => ({
          ...prev,
          subBranches: prev.subBranches.filter(sb => sb.id !== branchId)
        }));
      }
      setConfirmDelete(null);
      await refreshBranches();
    } catch (error) {
      console.error('Error deleting branch:', error);
      setError('Failed to delete branch. Please try again.');
    }
  };

  const renderContent = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!selectedBranch && activeTab !== 'branches') return <div>Please select a branch to view settings.</div>;

    switch (activeTab) {
      case 'technicians':
        return (
          <div>
            <h2>Technicians</h2>
            <form onSubmit={handleSubmit} className="add-form">
              {['name', 'email', 'phone'].map(field => (
                <input
                  key={field}
                  type={field === 'email' ? 'email' : 'text'}
                  name={field}
                  value={newItem[field]}
                  onChange={handleInputChange}
                  placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                  required
                />
              ))}
              <button type="submit">Add Technician</button>
            </form>
            <ul className="item-list">
              {data.technicians.map(tech => (
                <li key={tech.id}>
                  <span>{tech.name} - {tech.email} - {tech.phone}</span>
                  <button onClick={() => handleDelete(tech.id)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
        );
      case 'contractor':
        return (
          <div>
            <h2>Contractors</h2>
            <form onSubmit={handleSubmit} className="add-form">
              {['name', 'contact', 'email', 'phone'].map(field => (
                <input
                  key={field}
                  type={field === 'email' ? 'email' : 'text'}
                  name={field}
                  value={newItem[field]}
                  onChange={handleInputChange}
                  placeholder={field === 'name' ? 'Company Name' : field.charAt(0).toUpperCase() + field.slice(1)}
                  required
                />
              ))}
              <button type="submit">Add Contractor</button>
            </form>
            <ul className="item-list">
              {data.contractors.map(contractor => (
                <li key={contractor.id}>
                  <span>{contractor.name} - {contractor.contact} - {contractor.email} - {contractor.phone}</span>
                  <button onClick={() => handleDelete(contractor.id)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
        );
      case 'emailSettings':
        return (
          <EmailSettings
            initialSettings={data.emailSettings}
            onSave={handleSaveEmailSettings}
            onClose={() => setActiveTab('main')}
            branchId={selectedSubBranch ? selectedSubBranch.id : selectedBranch.id}
          />
        );
      case 'branches':
        return (
          <div>
            <h2>Manage Branches</h2>
            <form onSubmit={(e) => handleAddBranch(e, 'main')} className="add-form">
              <input
                type="text"
                value={newBranch.main}
                onChange={(e) => setNewBranch({ ...newBranch, main: e.target.value })}
                placeholder="New Main Branch Name"
                required
              />
              <button type="submit">Add Main Branch</button>
            </form>
            {selectedBranch && (
              <form onSubmit={(e) => handleAddBranch(e, 'sub')} className="add-form">
                <input
                  type="text"
                  value={newBranch.sub}
                  onChange={(e) => setNewBranch({ ...newBranch, sub: e.target.value })}
                  placeholder="New Sub-Branch Name"
                  required
                />
                <button type="submit">Add Sub-Branch</button>
              </form>
            )}
            <h3>Main Branches</h3>
            <ul className="branch-list">
              {branches.map(branch => (
                <li key={branch.id} className="branch-item">
                  <span className="branch-name">{branch.name}</span>
                  <div className="branch-actions">
                    <button className="select-btn" onClick={() => setSelectedBranch(branch)}>Select</button>
                    <button className="delete-btn" onClick={() => setConfirmDelete(branch)}>Delete</button>
                  </div>
                </li>
              ))}
            </ul>
            {selectedBranch && (
              <>
                <h3>Sub-Branches of {selectedBranch.name}</h3>
                <ul className="branch-list">
                  {selectedBranch.subBranches && selectedBranch.subBranches.map(subBranch => (
                    <li key={subBranch.id} className="branch-item">
                      <span className="branch-name">{subBranch.name}</span>
                      <div className="branch-actions">
                        <button className="delete-btn" onClick={() => setConfirmDelete(subBranch)}>Delete</button>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {confirmDelete && (
              <div className="confirm-delete">
                <p>Are you sure you want to delete the {confirmDelete.parentBranchId ? "sub-branch" : "branch"} "{confirmDelete.name}"? All related data will be lost.</p>
                <div className="confirm-actions">
                  <button onClick={() => handleDeleteBranch(confirmDelete.id)} className="delete-btn">Delete</button>
                  <button onClick={() => setConfirmDelete(null)} className="cancel-btn">Cancel</button>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="settings">
      <h1>Settings for {selectedSubBranch ? `${selectedBranch.name} - ${selectedSubBranch.name}` : selectedBranch?.name || 'All Branches'}</h1>
      <div className="settings-header">
        <button onClick={() => navigate('/dashboard')} className="back-button">Back to Dashboard</button>
        <div className="tabs">
          {['technicians', 'contractor', 'emailSettings', 'branches'].map(tab => (
            <button key={tab} onClick={() => setActiveTab(tab)}>{tab.charAt(0).toUpperCase() + tab.slice(1)}</button>
          ))}
        </div>
      </div>
      {renderContent()}
    </div>
  );
}

export default Settings;