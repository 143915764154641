import React, { useState, useRef, useEffect } from 'react';
import ProjectForm from './ProjectForm';
import '../styles/Calendar.css';
import { useBranch } from '../BranchContext';

const POPUP_DELAY = 500;
const POPUP_CLOSE_DELAY = 300;

const ProjectItem = ({ 
  project, 
  index, 
  expanded, 
  onMouseEnter, 
  onMouseLeave, 
  onClick,
  colors 
}) => {
  const color = colors[index % colors.length];
  const codIndicator = project.cod ? '💰' : '';
  
  const handleMouseEnter = (e) => {
    e.stopPropagation();
    onMouseEnter(e);
  };

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    onMouseLeave(e);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div 
      className={expanded ? "project-item-expanded" : "project-item"}
      style={{ 
        backgroundColor: expanded ? 'transparent' : color, 
        cursor: 'pointer',
        position: 'relative',
        zIndex: 1
      }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        {codIndicator} {expanded ? project.name : (project.name.length > 10 ? project.name.substring(0, 10) + '...' : project.name)}
      </div>
      {expanded && (
        <div className="project-time">
          {new Date(project.dateTime).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit'
          })}
        </div>
      )}
    </div>
  );
};

const ProjectPopup = ({ project, position, onMouseEnter, onMouseLeave }) => {
  if (!project) return null;

  const projectTechnicians = project.technicians || [];
  const projectContractors = project.contractors || [];

  const formatCurrency = (amount) => {
    if (!amount) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  return (
    <div 
      className="project-popup"
      style={{ 
        left: position.x,
        top: position.y,
        zIndex: 1000
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <h4>{project.name}</h4>
      <p><strong>Customer ID:</strong> {project.customerId || 'N/A'}</p>
      <p><strong>Document #:</strong> {project.documentNumber || 'N/A'}</p>
      <p><strong>Date/Time:</strong> {new Date(project.dateTime).toLocaleString()}</p>
      <p><strong>Location:</strong> {project.location}</p>
      <p><strong>Scope:</strong> {project.scope || 'N/A'}</p>
      <p><strong>Technicians:</strong> {projectTechnicians.length > 0 
        ? projectTechnicians.map(t => t.name).join(', ') 
        : 'N/A'}</p>
      <p><strong>Contractors:</strong> {projectContractors.length > 0
        ? projectContractors.map(c => c.name).join(', ')
        : 'N/A'}</p>
      <p><strong>Parts Required:</strong> {project.partsRequired ? 'Yes' : 'No'}</p>
      <p><strong>C.O.D.:</strong> {project.cod ? 'Yes' : 'No'}</p>
      <p><strong>Amount Due:</strong> {project.cod ? formatCurrency(project.amountDue) : 'N/A'}</p>
      <p><strong>Notes:</strong> {project.notes || 'N/A'}</p>
      <p><strong>Branch:</strong> {project.branch ? `${project.branch.name}${project.branch.parentBranch ? ` (${project.branch.parentBranch.name})` : ''}` : 'N/A'}</p>
    </div>
  );
};

function Calendar({ 
  selectedDate, 
  viewMode, 
  onAddProject, 
  onUpdateProject, 
  onDeleteProject,
  onDateChange,
  projects = [], 
  technicians = [], 
  contractors = [] 
}) {
const [hoveredProject, setHoveredProject] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [editingProject, setEditingProject] = useState(null);
  const calendarRef = useRef(null);
  const { selectedBranch, selectedSubBranch } = useBranch();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDayProjects, setSelectedDayProjects] = useState(null);
  const [popupTimeoutId, setPopupTimeoutId] = useState(null);
  const [closeTimeoutId, setCloseTimeoutId] = useState(null);

  const clearTimeouts = () => {
    if (popupTimeoutId) {
      clearTimeout(popupTimeoutId);
      setPopupTimeoutId(null);
    }
    if (closeTimeoutId) {
      clearTimeout(closeTimeoutId);
      setCloseTimeoutId(null);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setHoveredProject(null);
        setShowPopup(false);
        setSelectedDayProjects(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      clearTimeouts();
      setSelectedDayProjects(null);
    };
  }, []);

  // Holiday calculation helper functions
  const getEasterDate = (year) => {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const month = Math.floor((h + l - 7 * m + 114) / 31) - 1;
    const day = ((h + l - 7 * m + 114) % 31) + 1;
    return new Date(year, month, day);
  };

  const getHoliday = (date) => {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    // Fixed holidays
    if (month === 0 && day === 1) return "New Year's Day";
    if (month === 3 && day === 1) return "April Fools' Day";
    if (month === 6 && day === 4) return "Independence Day";
    if (month === 9 && day === 31) return "Halloween";
    if (month === 10 && day === 11) return "Veterans Day";
    if (month === 11 && day === 25) return "Christmas Day";
    if (month === 11 && day === 31) return "New Year's Eve";

    // Calculate MLK Day (Third Monday in January)
    if (month === 0) {
      const firstMonday = new Date(year, 0, 1);
      while (firstMonday.getDay() !== 1) {
        firstMonday.setDate(firstMonday.getDate() + 1);
      }
      const mlkDay = new Date(firstMonday);
      mlkDay.setDate(mlkDay.getDate() + 14);
      if (day === mlkDay.getDate()) return "Martin Luther King Jr. Day";
    }

    // Calculate Memorial Day (Last Monday in May)
    if (month === 4) {
      const lastMonday = new Date(year, 4, 31);
      while (lastMonday.getDay() !== 1) {
        lastMonday.setDate(lastMonday.getDate() - 1);
      }
      if (day === lastMonday.getDate()) return "Memorial Day";
    }

    // Calculate Labor Day (First Monday in September)
    if (month === 8) {
      const firstMonday = new Date(year, 8, 1);
      while (firstMonday.getDay() !== 1) {
        firstMonday.setDate(firstMonday.getDate() + 1);
      }
      if (day === firstMonday.getDate()) return "Labor Day";
    }

    // Calculate Thanksgiving (Fourth Thursday in November)
    if (month === 10) {
      const thanksgiving = new Date(year, 10, 1);
      while (thanksgiving.getDay() !== 4) {
        thanksgiving.setDate(thanksgiving.getDate() + 1);
      }
      thanksgiving.setDate(thanksgiving.getDate() + 21);
      if (day === thanksgiving.getDate()) return "Thanksgiving";
    }

    // Calculate Easter
    const easter = getEasterDate(year);
    if (month === easter.getMonth() && 
        day === easter.getDate() && 
        year === easter.getFullYear()) return "Easter";

    return null;
  };

  const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();

  const handleDayClick = (date) => {
    onAddProject(date);
  };

  const handleProjectClick = (e, project) => {
    e.stopPropagation();
    onUpdateProject(project);
  };

  const handleProjectHover = (e, project) => {
    e.stopPropagation();
    clearTimeouts();

    const timeoutId = setTimeout(() => {
      setHoveredProject(project);
      const rect = calendarRef.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      
      let x = e.clientX - rect.left + scrollLeft;
      let y = e.clientY - rect.top + scrollTop;

      // Adjust position to prevent popup from going off-screen
      const popupWidth = 300;
      const popupHeight = 200;
      if (x + popupWidth > rect.width) {
        x = rect.width - popupWidth - 10;
      }
      if (y + popupHeight > rect.height) {
        y = y - popupHeight - 10;
      }

      setPopupPosition({ x, y });
      setShowPopup(true);
    }, POPUP_DELAY);

    setPopupTimeoutId(timeoutId);
  };

  const handleProjectLeave = (e) => {
    e.stopPropagation();
    clearTimeouts();

    const timeoutId = setTimeout(() => {
      setShowPopup(false);
      setHoveredProject(null);
    }, POPUP_CLOSE_DELAY);

    setCloseTimeoutId(timeoutId);
  };

  const handlePopupHover = () => {
    clearTimeouts();
  };

  const handlePopupLeave = () => {
    clearTimeouts();
    const timeoutId = setTimeout(() => {
      setShowPopup(false);
      setHoveredProject(null);
    }, POPUP_CLOSE_DELAY);
    setCloseTimeoutId(timeoutId);
  };

  const handleMoreProjectsClick = (e, projects, date) => {
    e.stopPropagation();
    setSelectedDayProjects({
      projects: projects.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime)),
      date: date
    });
  };
const colors = ['#FFB3BA', '#BAFFC9', '#BAE1FF', '#FFFFBA', '#FFD700', '#98FB98', '#DDA0DD'];
  
  const formatCurrency = (amount) => {
    if (!amount) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  const filteredProjects = projects.filter(project => {
    if (!selectedBranch) return false;
    
    if (selectedSubBranch) {
      return project.branch?.id === selectedSubBranch.id;
    }
    
    return project.branch?.id === selectedBranch.id || 
      (selectedBranch.subBranches && 
       selectedBranch.subBranches.some(sub => sub.id === project.branch?.id));
  });

  const getHeaderText = () => {
    const branchName = selectedSubBranch ? 
      `${selectedBranch.name} - ${selectedSubBranch.name}` : 
      selectedBranch?.name || 'All Branches';

    return branchName;
  };

  const renderProjectItem = (project, index, expanded = false) => {
    return (
      <ProjectItem
        key={project.id}
        project={project}
        index={index}
        expanded={expanded}
        onMouseEnter={(e) => handleProjectHover(e, project)}
        onMouseLeave={handleProjectLeave}
        onClick={(e) => handleProjectClick(e, project)}
        colors={colors}
      />
    );
  };

  const DayProjectsModal = ({ data, onClose, onProjectClick }) => {
    if (!data) return null;
    
    const holidayName = getHoliday(data.date);

    const handleProjectClick = (e, project) => {
      e.stopPropagation();
      onProjectClick(e, project);
      onClose();
    };

    return (
      <div className="day-projects-modal-overlay" onClick={onClose}>
        <div className="day-projects-modal" onClick={e => e.stopPropagation()}>
          <div className="day-projects-modal-header">
            <h3 className="day-projects-modal-title">
              Projects for {data.date.toLocaleDateString(undefined, {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
              {holidayName && <span className="holiday-indicator"> - {holidayName}</span>}
            </h3>
            <button className="day-projects-modal-close" onClick={onClose}>×</button>
          </div>
          <div className="day-projects-list">
            {data.projects.map((project, index) => (
              <div 
                key={project.id} 
                className="project-item-expanded"
                onClick={(e) => handleProjectClick(e, project)}
                style={{ cursor: 'pointer' }}
              >
                <div className="project-details">
                  <p><strong>Customer ACCT:</strong> {project.customerId || 'N/A'}</p>
                  <p><strong>Document #:</strong> {project.documentNumber || 'N/A'}</p>
                  <p><strong>Customer Name:</strong> {project.name}</p>
                  <p><strong>Project Scope:</strong> {project.scope || 'N/A'}</p>
                  <p><strong>Technicians:</strong> {project.technicians?.length > 0 
                    ? project.technicians.map(t => t.name).join(', ') 
                    : 'N/A'}
                  </p>
                  <div className="project-time">
                    {new Date(project.dateTime).toLocaleTimeString([], { 
                      hour: '2-digit', 
                      minute: '2-digit'
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderProjectPopup = () => {
    if (!showPopup || !hoveredProject) return null;

    return (
      <ProjectPopup
        project={hoveredProject}
        position={popupPosition}
        onMouseEnter={handlePopupHover}
        onMouseLeave={handlePopupLeave}
      />
    );
  };

  const renderCalendarContent = () => {
    switch (viewMode) {
      case 'day':
        return (
          <div className="day-view" onClick={() => handleDayClick(selectedDate)}>
            <div className="day-timeline">
              {Array.from({ length: 24 }, (_, i) => {
                const currentHourDate = new Date(selectedDate);
                currentHourDate.setHours(i, 0, 0, 0);
                const holidayName = i === 0 ? getHoliday(currentHourDate) : null;
                
                const dayProjects = filteredProjects.filter(project => {
                  const projectDate = new Date(project.dateTime);
                  return projectDate.getFullYear() === selectedDate.getFullYear() &&
                         projectDate.getMonth() === selectedDate.getMonth() &&
                         projectDate.getDate() === selectedDate.getDate();
                });
                
                return (
                  <div key={`hour-${i}`} className="timeline-hour">
                    <span className="hour-label">
                      {i.toString().padStart(2, '0')}:00
                      {holidayName && <span className="holiday-indicator"> - {holidayName}</span>}
                    </span>
                    <div className="hour-content">
                      {dayProjects
                        .filter(project => new Date(project.dateTime).getHours() === i)
                        .map((project, index) => renderProjectItem(project, index))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      case 'week':
        return (
          <div className="week-view">
            {Array.from({ length: 7 }, (_, i) => {
              const currentDate = new Date(selectedDate);
              currentDate.setDate(selectedDate.getDate() - selectedDate.getDay() + i);
              const holidayName = getHoliday(currentDate);
              const dayProjects = filteredProjects.filter(project => 
                new Date(project.dateTime).toDateString() === currentDate.toDateString()
              );
              
              return (
                <div key={`week-day-${i}`} className="week-day" onClick={() => handleDayClick(currentDate)}>
                  <div className="day-header">
                    {currentDate.toLocaleDateString('en-US', { 
                      weekday: 'short', 
                      month: 'short', 
                      day: 'numeric' 
                    })}
                    {holidayName && <div className="holiday-indicator">{holidayName}</div>}
                  </div>
                  <div className="day-content">
                    {dayProjects.slice(0, 5).map((project, index) => renderProjectItem(project, index))}
                    {dayProjects.length > 5 && (
                      <div 
                        className="more-projects"
                        onClick={(e) => handleMoreProjectsClick(e, dayProjects, currentDate)}
                      >
                        +{dayProjects.length - 5} more
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      default: // month view
        return (
          <div className="calendar-grid month-view">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <div key={`label-${day}`} className="calendar-day-label">{day}</div>
            ))}
            {Array.from({ length: firstDayOfMonth }).map((_, i) => (
              <div key={`empty-${i}`} className="calendar-day empty"></div>
            ))}
            {Array.from({ length: daysInMonth }).map((_, i) => {
              const currentDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), i + 1);
              const holidayName = getHoliday(currentDate);
              const dayProjects = filteredProjects.filter(project => 
                new Date(project.dateTime).getDate() === i + 1 &&
                new Date(project.dateTime).getMonth() === selectedDate.getMonth() &&
                new Date(project.dateTime).getFullYear() === selectedDate.getFullYear()
              );
              
              return (
                <div 
                  key={`day-${i + 1}`} 
                  className={`calendar-day ${dayProjects.length > 0 ? 'has-projects' : ''}`} 
                  onClick={() => handleDayClick(currentDate)}
                >
                  <div className="date-header">
                    <span className="day-number">{i + 1}</span>
                    {holidayName && <div className="holiday-indicator">{holidayName}</div>}
                  </div>
                  <div className="project-list">
                    {dayProjects.slice(0, 3).map((project, index) => renderProjectItem(project, index))}
                    {dayProjects.length > 0 && (
                      <div 
                        className="more-projects"
                        onClick={(e) => handleMoreProjectsClick(e, dayProjects, currentDate)}
                      >
                        View All ({dayProjects.length})
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
    }
  };

  return (
    <div className="calendar" ref={calendarRef}>
      <div className="calendar-header">
        <h2>{getHeaderText()}</h2>
      </div>
      {renderCalendarContent()}
      {showPopup && hoveredProject && renderProjectPopup()}
      {selectedDayProjects && (
        <DayProjectsModal 
          data={selectedDayProjects} 
          onClose={() => setSelectedDayProjects(null)}
          onProjectClick={handleProjectClick}        
        />
      )}
    </div>
  );
}

export default Calendar;