import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://project-scheduler-app.onrender.com/api';

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  withCredentials: true
});

const handleError = (error) => {
  console.error('API error:', error.message);
  console.error('Response data:', error.response?.data);
  console.error('Response status:', error.response?.status);
  console.error('Response headers:', error.response?.headers);
  throw error;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const apiService = {
  // Auth
  login: (email, password) => axiosInstance.post('/login', { email, password }).catch(handleError),
  register: (userData) => axiosInstance.post('/register', userData).catch(handleError),
  confirmEmail: (email, confirmationCode) => axiosInstance.post('/confirm-email', { email, confirmationCode }).catch(handleError),

  // Users
  getUsers: () => axiosInstance.get('/users').catch(handleError),
  updateUser: (id, userData) => axiosInstance.put(`/users/${id}`, userData).catch(handleError),
  deleteUser: (id) => axiosInstance.delete(`/users/${id}`).catch(handleError),

  // Branches
  getBranch: (parentBranchId) => axiosInstance.get('/branch', { params: { parentBranchId } }).catch(handleError),
  createBranch: (branchData) => axiosInstance.post('/branch', branchData).catch(handleError),
  updateBranch: (id, branchData) => axiosInstance.put(`/branch/${id}`, branchData).catch(handleError),
  deleteBranch: (id) => axiosInstance.delete(`/branch/${id}`).catch(handleError),

  // Projects
  getProjects: (branchId) => axiosInstance.get('/projects', { params: { branchId } }).catch(handleError),
  createProject: (projectData) => axiosInstance.post('/projects', projectData).catch(handleError),
  updateProject: (id, projectData) => axiosInstance.put(`/projects/${id}`, projectData).catch(handleError),
  deleteProject: (id) => axiosInstance.delete(`/projects/${id}`).catch(handleError),

  // Technicians
  getTechnicians: (branchId) => axiosInstance.get('/technicians', { params: { branchId } }).catch(handleError),
  createTechnician: (technicianData) => axiosInstance.post('/technicians', technicianData).catch(handleError),
  updateTechnician: (id, technicianData) => axiosInstance.put(`/technicians/${id}`, technicianData).catch(handleError),
  deleteTechnician: (id) => axiosInstance.delete(`/technicians/${id}`).catch(handleError),

  // Contractors
  getContractor: (branchId) => axiosInstance.get('/contractor', { params: { branchId } }).catch(handleError),
  createContractor: (contractorData) => axiosInstance.post('/contractor', contractorData).catch(handleError),
  updateContractor: (id, contractorData) => axiosInstance.put(`/contractor/${id}`, contractorData).catch(handleError),
  deleteContractor: (id) => axiosInstance.delete(`/contractor/${id}`).catch(handleError),

  // Email Settings
  getEmailSettings: (branchId) => axiosInstance.get('/emailSettings', { params: { branchId } }).catch(handleError),
  updateEmailSettings: (settingsData) => axiosInstance.post('/emailSettings', settingsData).catch(handleError),

  // AR Review
  sendARSchedule: (branchId, subBranchId) => axiosInstance.post('/ar-review', { branchId, subBranchId }).catch(handleError),
  getARReview: (token) => axiosInstance.get(`/ar-review/${token}`).catch(handleError),
  submitARReview: (token, updates) => axiosInstance.post(`/ar-review/${token}`, { updates: Array.isArray(updates) ? updates : updates.updates }).catch(handleError),

  // Weekly Schedule
  sendWeeklySchedule: (branchId, subBranchId) => axiosInstance.post('/sendWeeklySchedule', { branchId, subBranchId }).catch(handleError),

  // Email Status
  getEmailDeliveryStatus: (projectId) => axiosInstance.get(`/projects/${projectId}/email-status`).catch(handleError),
};

export default apiService;